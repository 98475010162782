import { SplitText } from "@cyriacbr/react-split-text";
import * as React from "react";
import Helmet from "react-helmet";
import eventBus from "../components/utils/eventBus";
import gsap from "gsap";

// import ParallaxSection from '../components/values/parallaxSection.jsx'
import GravitySection from '../components/values/gravitySection.jsx'
/** Import Image */
import testingImg from "../images/testing-image.jpg";

import valeurDesktop from '../images/src/pages/valeurs/desktop/valeurs_1_desktop.jpg'
import valeurDesktopR from '../images/src/pages/valeurs/desktop/valeurs_1_desktop@2x.jpg'

import atelierOneDesktop from '../images/src/pages/valeurs/desktop/atelier_1_desktop.jpg'
import atelierOneDesktopR from '../images/src/pages/valeurs/desktop/atelier_1_desktop@2x.jpg'

import atelierTwoDesktop from '../images/src/pages/valeurs/desktop/atelier_2_desktop.jpg'
import atelierTwoDesktopR from '../images/src/pages/valeurs/desktop/atelier_2_desktop@2x.jpg'

import valeurMobile from '../images/src/pages/valeurs/mobile/valeurs_1_mobile.jpg'
import valeurMobileR from '../images/src/pages/valeurs/mobile/valeurs_1_mobile@2x.jpg'

import atelierOneMobile from '../images/src/pages/valeurs/mobile/atelier_1_mobile.jpg'
import atelierOneMobileR from '../images/src/pages/valeurs/mobile/atelier_1_mobile@2x.jpg'

import atelierTwoMobile from '../images/src/pages/valeurs/mobile/atelier_2_mobile.jpg'
import atelierTwoMobileR from '../images/src/pages/valeurs/mobile/atelier_2_mobile@2x.jpg'

import "../styles/valeurs/index.scss";
import { Link } from "gatsby";

class Valeur extends React.Component {
  componentDidMount() {
    // Open one details at the same time 
    const details = document.querySelectorAll(".appro details");
    let baseHeight;
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", (event) => {
        // Close all the details that are not targetDetail.
        event.preventDefault();

        details.forEach((detail) => {
          if (detail === targetDetail && !detail.getAttribute("open")) {
            this.openDetail(detail);
          } else {
            this.closeDetail(detail);
          }
        }
        );
      });
    });

  }

  observerMotion = (el, trigger) => {
    let target = el;
    let prevRatio = 0.0;
    let prevY = 0.0;

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > prevRatio) {
          // Enter by top
          if (entry.boundingClientRect.y > prevY) {
            trigger(el);
            observer.unobserve(target);
          }
        } else {
        }
      });
    };

    let observer = new IntersectionObserver(callback);
    observer.observe(target);
  };

  triggerSecondaryTitle(el) {
    let tl = gsap.timeline();
    tl.fromTo(
      el.querySelectorAll(".line"),
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
    );
  }

  openDetail = (detail) => {
    console.log("open Details", detail);
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    let baseHeight = detail.querySelector('summary').getBoundingClientRect().height;
    console.log("BaseHeight", baseHeight);
    detail.setAttribute("open", "true");
    let targetHeight = detail.getBoundingClientRect().height;
    let tl = gsap.timeline();
    tl.fromTo(detail, { height: baseHeight }, { height: targetHeight });
  }

  closeDetail = (detail) => {
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    gsap.killTweensOf(detail.querySelector('p'));
    detail.querySelector('p').style = " ";
    console.log("close Detail", detail)
    let tl = gsap.timeline();
    tl.fromTo(detail, { height: detail.getBoundingClientRect().height, ease: "easeOutExpo" }, { height: detail.querySelector("summary").getBoundingClientRect().height, ease: "easeOutExpo", onComplete: () => { detail.removeAttribute("open") } })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Nos valeurs | Menuiserie Perret</title>
          <meta
            name="description"
            type="description"
            content="Nos engagements pour la planète ainsi que pour les hommes et les femmes qui travaillent à nos côtés et notre circuit d'approvisionnement en matières premières."
          />
        </Helmet>
       {/* <ParallaxSection></ParallaxSection>*/} 
       <GravitySection></GravitySection>
        <section className="content-plus-image">
          <picture className="valeur-header-picture">
            <source media="(min-width: 770px)" srcSet={valeurDesktopR + ' 2x, ' + valeurDesktop + ' 1x'} />
            <img src={valeurMobile} srcSet={valeurMobileR + ' 2x, ' + valeurMobile + ' 1x'} alt="Image de test"></img>
          </picture>
          <div className="content">
          <p>
              Dans cette optique, notre entreprise s'engage dans une démarche de
              Responsabilité Sociétale des Entreprises, et se forme aux bonnes
              pratiques du développement durable, grâce à des formations dédiées
              aux entreprises du B.T.P.
            </p>
            <p>
              Dans cette objectif, nous avons suivi un accompagnement d'Acta
              Qualité pour mettre en place de nouvelles méthodes de travail.
            </p>
            <Link to="/api-batisseurs" style={{
              textDecoration: 'underline'
            }}>Nous sommes API Bâtisseurs</Link>
          </div>
          </section>
        <section className="formations" id="formations">
          <h2 className="big-title">Formations professionnelles</h2>
          <picture>
            <source media="(min-width: 770px)" srcSet={atelierOneDesktopR + ' 2x, ' + atelierOneDesktop + ' 1x'} />
            <img src={atelierOneMobile} srcSet={atelierOneMobileR + ' 2x, ' + atelierOneMobile + ' 1x'} alt="Image de test"></img>
          </picture>
          <p>
            Nous nous faisons le devoir d'investir dans la formation. Nous
            consacrons un budget de 3 à 4% par an à la formation de nos salariés
            et des jeunes apprentis. Nous accueillons chaque année des jeunes en
            stage, en contrat d'apprentissage, ou des adultes en reconversion
            professionnelle, dans le but de les accompagner dans le monde de
            l'entreprise et confirmer leur choix professionnel.
          </p>
          <div className="white-b"></div>
        </section>
        <section className="appro">
          <div className="content">
            <h2 className="tertiary-title">
              Approvisionnement et matières premières
            </h2>
            <p>
              Nous utilisons différentes essences de bois pour nos travaux
              d'aménagements intérieurs et extérieurs. Soucieux de
              l'environnement et de la provenance de nos matières premières,
              chacune de nos essences est choisit avec soin.
            </p>
          </div>
          <p>Essences de bois et provenances :</p>
          <ul>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Sapin et Epicéa (résineux)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Communauté européenne <br />
                  <b>Réalisations :</b> plinthes, habillages, ossatures <br />
                  <b>Certifications :</b> certification F.S.C. ou P.E.F.C.
                  suivant provenance
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Pin sylvestre et mélèze (résineux)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Communauté européenne <br />
                  <b>Réalisations :</b> menuiseries extérieures de teinte claire
                  et travaux d’agencement <br />
                  <b>Certifications :</b> certification P.E.F.C. suivant
                  provenance <br />
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Chêne (feuillu)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Forêts françaises <br />
                  <b>Réalisations :</b> menuiseries extérieures et travaux
                  d’agencement à vernir (escaliers, portes, agencements
                  intérieurs) <br />
                  <b>Certifications :</b> certification P.E.F.C
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Hêtre (feuillu)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Forêts françaises <br />
                  <b>Réalisations :</b> menuiseries extérieures et travaux
                  d’agencement à vernir (escaliers, portes, agencements
                  intérieurs) <br />
                  <b>Certifications :</b> certification P.E.F.C
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Eucalyptus Grandis (exotique)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Australie ou Brésil <br />
                  <b>Réalisations :</b> menuiseries extérieures ou en carrelets
                  3 plis <br />
                  <b>Certifications :</b> certification F.S.C.
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Sipo (exotique)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b> Provenance :</b> Bassin du Congo (Afrique), exploitation
                  contrôlée et gérée durablement <br />
                  <b>Réalisations :</b> menuiseries extérieure
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Touari (exotique)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Brésil <br />
                  <b>Réalisations :</b> menuiseries intérieurs et extérieures{" "}
                  <br />
                  <b>Certifications :</b> certification F.S.C. suivant
                  disponibilité
                </p>
              </details>
            </li>
            <li className="js_details">
              <details>
                <summary>
                  <div>
                    <h3>Dark Red Méranti (exotique)</h3>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.125 17.875H6.875V15.125H26.125V17.875Z" fill="#23201D" />
                      <path d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z" fill="#23201D" />
                    </svg>

                  </div>
                </summary>
                <p>
                  <b>Provenance :</b> Sud Est Asiatique <br />
                  <b>Certifications :</b> certification P.E.F.C.
                </p>
              </details>
            </li>
          </ul>
        </section>
        <section className="dechets">
          <p>Gestion des déchets</p>
          <h2 className="secondary-title">
            <div>
              <SplitText
                LineWrapper={({ lineIndex, children }) => (
                  <span className={"line wrapper" + " line" + lineIndex}>
                    {children}
                  </span>
                )}
              >
                Conscient des enjeux planétaires, nous recyclons l'ensemble des
                produits que nous produisons.
              </SplitText>
            </div>
          </h2>
          <picture>
            <source media="(min-width: 770px)" srcSet={atelierTwoDesktopR + ' 2x, ' + atelierTwoDesktop + ' 1x'} />
            <img src={atelierTwoMobile} srcSet={atelierTwoMobileR + ' 2x, ' + atelierTwoMobile + ' 1x'} alt="Image de test"></img>
          </picture>
          <div className="content">
            <p>
              Nous séparons les déchets banals des déchets bois. Ces dernier sont traités par l'entreprise Valorsol , avec qui nous sommes partenaire, pour être valorisés.{" "}
            </p>
            <p>
              Nous nous sommes également équipés de bennes spécifiques et de
              broyeur pour réduire les déchets pouvant être valorisés, comme le
              bois en copeaux. Ces derniers sont stockés et réutilisés pour le
              chauffage de nos locaux.
            </p>
            <p>
              La politique de l’entreprise vise à l’absence d’accumulation de
              déchets, tant au niveau de la fabrication, que des chantiers. Nous
              veillons, sur chaque chantier, à ce que nos emballages soient
              évacués et rapatriés dans nos ateliers où nous disposons de
              l'ensemble du matériel nécessaire au tri de ces déchets.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default Valeur;
