import * as React from "react";
import Matter from "matter-js";
import gsap from "gsap";
import { SplitText } from "@cyriacbr/react-split-text";
import eventBus from "../utils/eventBus";

import * as sectionStyle from "../../styles/valeurs/parallaxSection.module.scss";
import * as gSectionStyle from "../../styles/valeurs/gravitySection.module.scss";

const GravitySection = () => {
  const bodyE = React.useRef(null);
  const parallaxWrapper = React.useRef(null);
  let initied = null;

  const getPxPosition = (value, total) => {
    return (value * total) / 100;
  };

  const degrees_to_radians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  const setupGravity = () => {
    let Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Bodies = Matter.Bodies,
      Events = Matter.Events,
      Composite = Matter.Composite,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse;

    let engine = Engine.create();

    let render = Render.create({
      element: parallaxWrapper.current,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: 2,
        background: "transparent",
        wireframes: false,
      },
    });

    engine.gravity.y = 1;
    engine.gravity.x = 0;

    let mouse = Mouse.create(render.canvas);
    let mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        render: { visible: false },
        stiffness: 1,
      },
    });

    mouseConstraint.mouse.element.removeEventListener(
      "mousewheel",
      mouseConstraint.mouse.mousewheel
    );
    mouseConstraint.mouse.element.removeEventListener(
      "DOMMouseScroll",
      mouseConstraint.mouse.mousewheel
    );
    render.mouse = mouse;

    // create two boxes and a ground
    let wW = window.innerWidth;
    let wH = window.innerHeight;
    let mob = wW < 770 ? true : false;
    var boxA = Bodies.rectangle(
      getPxPosition(80, wW),
      getPxPosition(10, wH),
      mob ? 155 / 2 : 155,
      mob ? 155 / 2 : 155,
      {
        render: {
          fillStyle: "#a67458",
        },
        angle: degrees_to_radians(-15),
      }
    );
    var boxB = Bodies.rectangle(
      getPxPosition(50, wW),
      getPxPosition(10, wH),
      mob ? 105 / 2 : 105,
      mob ? 206 / 2 : 206,
      {
        render: {
          fillStyle: "#a67458",
        },
        angle: degrees_to_radians(15),
      }
    );

    var boxC = Bodies.rectangle(
      getPxPosition(20, wW),
      getPxPosition(10, wH),
      mob ? 173 / 2 : 173,
      mob ? 312 / 2 : 312,
      {
        render: {
          fillStyle: "#cfae8a",
        },
        angle: degrees_to_radians(-35),
      }
    );

    var boxD = Bodies.rectangle(
      getPxPosition(40, wW),
      getPxPosition(10, wH),
      mob ? 58 / 2 : 58,
      mob ? 476 / 2 : 476,
      {
        render: {
          fillStyle: "#cfae8a",
        },
        angle: degrees_to_radians(85),
      }
    );

    var boxE = Bodies.rectangle(
      getPxPosition(65, wW),
      getPxPosition(10, wH),
      mob ? 356 / 2 : 356,
      mob ? 206 / 2 : 206,
      {
        render: {
          fillStyle: "#a67458",
        },
        angle: degrees_to_radians(15),
      }
    );

    let bodies = [boxA, boxB, boxC, boxD, boxE];
    Events.on(mouseConstraint, "mousemove", (ev) => {
      let foundPhysics = Matter.Query.point(bodies, ev.mouse.position);

      console.log(foundPhysics[0]);
      if (foundPhysics[0]) {
        document.querySelector("main").style.cursor = "grab";
      } else {
        document.querySelector("main").style.cursor = "auto";
      }
    });
    var roof = Bodies.rectangle(
      window.innerWidth / 2,
      -150,
      window.innerWidth,
      300,
      { isStatic: true }
    );
    var ground = Bodies.rectangle(
      window.innerWidth / 2,
      window.innerHeight + 150,
      window.innerWidth,
      300,
      { isStatic: true }
    );
    let leftWall = Bodies.rectangle(
      -150,
      window.innerHeight / 2,
      300,
      window.innerHeight,
      { isStatic: true }
    );
    let rightWall = Bodies.rectangle(
      window.innerWidth + 150,
      window.innerHeight / 2,
      300,
      window.innerHeight,
      { isStatic: true }
    );

    // add all of the bodies to the world
    Composite.add(engine.world, [
      boxA,
      boxB,
      boxC,
      boxD,
      boxE,
      ground,
      roof,
      leftWall,
      rightWall,
      mouse,
      mouseConstraint,
    ]);

    // run the renderer
    Render.run(render);

    // create runner
    var runner = Runner.create();

    // run the engine
    Runner.run(runner, engine);
  };

  const introduction = () => {
    if (
      !initied &
      (document.querySelector("main").getAttribute("id") === "/nos-valeurs" ||
        document.querySelector("main").getAttribute("id") === "/nos-valeurs/")
    ) {
      initied = true;
      if (window.innerWidth > 770) {
        setupGravity();
      }
      gsap.fromTo(parallaxWrapper.current, {opacity: 0}, {opacity: 1})
      gsap.fromTo(
        "h1 .line, p .line",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.6,
          delay: 0.5,
          onStart: () => {
            gsap.set("main h1", { opacity: 1 });
            gsap.set("main p", { opacity: 1 });
          },
        }
      );
      // gsap.fromTo(squares, {opacity: 0}, {opacity: 1, stagger: 0.10, delay: 0.25})
    }
  };

  React.useEffect(() => {
    gsap.set("main h1, p.line, main p", { opacity: 0 });
    eventBus.on("Finalised", introduction);
    return () => {
      eventBus.remove("Finalised", introduction);
    };
  }, [initied]);

  return (
    <section
      className={
        "valeursHeader " +
        sectionStyle.valeursHeader +
        " " +
        gSectionStyle.valeursGHeader
      }
      id="valeursHeader"
      ref={bodyE}
    >
      <div
        className={
          "parallax_wrapper " +
          sectionStyle.parallaxWrapper +
          " " +
          gSectionStyle.gravityWrapper
        }
        ref={parallaxWrapper}
      ></div>
      <h1 className={"big-title " + sectionStyle.firstTitle}>
        <SplitText
          LineWrapper={({ lineIndex, children }) => (
            <span className={"line wrapper" + " line" + lineIndex}>
              {children}
            </span>
          )}
        >
          L’homme et la planète au coeur de nos engagements
        </SplitText>
      </h1>
      <p className={"secondary-title " + sectionStyle.secondaryTitle}>
        <SplitText
          LineWrapper={({ lineIndex, children }) => (
            <span className={"line wrapper" + " line" + lineIndex}>
              {children}
            </span>
          )}
        >
          Nous nous engageons pour les femmes et les hommes qui travaillent à
          nos côtés, mais aussi pour notre planète et ses ressources naturelles.
        </SplitText>
      </p>
    </section>
  );
};

export default GravitySection;
